import React, { useMemo, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import cn from "classnames"
import Layout from "../../components/Layout/Layout"
import RichText from "../../components/RichText/RichText"

import * as styles from "./TermsOfUse.module.scss"

const TermsOfUse = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulTermsOfUse(
        sort: { fields: version, order: DESC }
        limit: 1
      ) {
        edges {
          node {
            nftAmendment {
              raw
            }
            masterSellerAgreement {
              raw
            }
            masterBuyerAgreement {
              raw
            }
            websiteTermsOfUse {
              raw
            }
            version
          }
        }
      }
    }
  `)

  const [activeTab, setActiveTab] = useState("websiteTermsOfUse")

  const richText = useMemo(() => {
    return data.allContentfulTermsOfUse.edges[0]?.node?.[activeTab]
  }, [activeTab])

  return (
    <>
      <div>
        <ul className={styles.termsList}>
          <li>
            <button
              className={cn({
                [styles.termsListBtnActive]: activeTab === "websiteTermsOfUse",
              })}
              onClick={() => setActiveTab("websiteTermsOfUse")}
            >
              Terms Of Use
            </button>
          </li>{" "}
          |
          <li>
            <button
              className={cn({
                [styles.termsListBtnActive]:
                  activeTab === "masterBuyerAgreement",
              })}
              onClick={() => setActiveTab("masterBuyerAgreement")}
            >
              Master Buyer Agreement
            </button>
          </li>{" "}
          |
          <li>
            <button
              className={cn({
                [styles.termsListBtnActive]:
                  activeTab === "masterSellerAgreement",
              })}
              onClick={() => setActiveTab("masterSellerAgreement")}
            >
              Master Seller Agreement
            </button>
          </li>{" "}
          |
          <li>
            <button
              className={cn({
                [styles.termsListBtnActive]: activeTab === "nftAmendment",
              })}
              onClick={() => setActiveTab("nftAmendment")}
            >
              NFT Amendment
            </button>
          </li>
        </ul>
      </div>
      <RichText richText={richText} />
    </>
  )
}

export default TermsOfUse
